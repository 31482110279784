var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"text-align":"center"}},[_c('div',{staticClass:"flex-v",style:({
      'padding-top': '6vh',
      'background-image': 'url(' + _vm.picUrl + ')',
      'background-repeat': 'no-repeat',
      'background-size': '100% 100%',
      '-moz-background-size': '100% 100%',
      'min-height': '800px',
    })},[_c('div',{staticStyle:{"color":"#fff","font-weight":"bold","font-size":"16px"}},[_vm._v(" 中奖人信息 ")]),_c('div',{staticStyle:{"padding":"0px 28px","color":"#515a6e","font-size":"12px","text-align":"left"}},[_c('div',[_vm._v("姓名："+_vm._s(_vm.name))]),_c('div',[_vm._v("手机："+_vm._s(_vm.mobile))]),_c('div',[_vm._v("地址："+_vm._s(_vm.address))])]),_c('div',{staticStyle:{"color":"#fff","font-weight":"bold","font-size":"16px","margin":"10px 0px"}},[_vm._v(" 奖品列表 ")]),_c('div',{staticStyle:{"font-size":"12px","text-align":"center","margin":"8px 0px"}},[_vm._v(" *兑奖时间：中奖之日起至2022年1月15日12:00 ")]),_c('div',{staticStyle:{"background":"#ecffff"}},_vm._l((_vm.list),function(item,i){return _c('div',{key:i,style:({
          'background-image': 'url(' + _vm.awardbg + ')',
          'background-repeat': 'no-repeat',
          'background-size': '100% 100%',
          '-moz-background-size': '100% 100%',
          height: '100px',
          width: '100%',
        })},[_c('van-row',{staticStyle:{"margin":"2px 28px","padding":"0px 4px","color":"#515a6e","font-size":"12px","text-align":"left","height":"100px"}},[_c('van-col',{attrs:{"span":"10"}},[_c('div',{staticStyle:{"color":"red","text-align":"center","line-height":"20px","padding-top":"30px","font-weight":"bold"}},[_vm._v(" "+_vm._s(item.prize_name)+" ")])]),_c('van-col',{attrs:{"span":"14"}},[_c('div',{staticStyle:{"text-align":"center","line-height":"40px","font-size":"12px","font-weight":"bold","margin-top":"10px"}},[_c('div',[_vm._v(_vm._s(item.shop_name))]),_c('van-tag',{attrs:{"type":"primary"}},[_vm._v(_vm._s(item.description))]),_c('van-tag',{staticStyle:{"margin-left":"10px"},attrs:{"type":"success"},on:{"click":function($event){if (item.status == 'PENDING') {
                    _vm.qrcode(item);
                  }}}},[_vm._v(_vm._s(_vm.statusFormate(item.status)))])],1)])],1)],1)}),0)]),_c('van-popup',{staticStyle:{"width":"70%"},model:{value:(_vm.showQrcode),callback:function ($$v) {_vm.showQrcode=$$v},expression:"showQrcode"}},[_c('div',{staticClass:"qrcode",staticStyle:{"padding":"10px"}},[_c('div',{ref:"qrCodeUrl",staticStyle:{"margin":"0 auto","width":"200px","height":"200px","display":"none"},attrs:{"id":"qrCodeDiv"}}),_c('div',{staticStyle:{"margin":"0 auto","width":"200px","height":"200px"}},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.QRUrl}})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }